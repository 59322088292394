import React from "react";
import { useStaticQuery, graphql, Link } from "gatsby";
import styled from "@emotion/styled";
import useMetadata from "../components/hooks/useMetadata";
import { jsx, css } from "@emotion/react";
import { FiChevronsLeft } from "react-icons/fi";
import { colors, H5, FeedTitle, FeedLink } from "../style/theme";
import MailingList from "../components/mailing-list";
import WriteWithUs from "../components/write-with-us";

const Section = styled.div({
  marginBottom: "2rem",
});

function RecentPosts() {
  const data = useStaticQuery(graphql`
    query RecentPosts {
      allMdx(
        sort: { fields: frontmatter___date, order: DESC }
        limit: 3
        filter: { frontmatter: { isBlog: { eq: true } } }
      ) {
        edges {
          node {
            id
            frontmatter {
              title
              path
              author
              image {
                childImageSharp {
                  fluid {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
      }
    }
  `);
  const posts = data.allMdx.edges.map((p) => p.node);
  return (
    <Section>
      <H5>مدوّنة الفهرست</H5>
      <WriteWithUs withLine={false} />
      <div css={{}}>
        <h5 css={{ marginBottom: "1rem" }}>أحدث التدوينات</h5>
        {posts.map((post) => (
          <div key={post.id}>
            <h5
              css={{
                fontWeight: 400,
                marginBottom: "0.8rem",
                cursor: "pointer",
                display: "flex",
                alignItems: "center",
              }}
            >
              <span css={{ marginLeft: "0.5rem", marginTop: "0.1rem" }}>
                <FiChevronsLeft />
              </span>
              <FeedLink to={post.frontmatter.path} as={Link}>
                {post.frontmatter.title}
              </FeedLink>
            </h5>
          </div>
        ))}
      </div>
    </Section>
  );
}

function Sidebar() {
  const { title, about } = useMetadata();
  return (
    <div
      css={{
        boxShadow: "1px 2px 15px #e5e5e5",
        margin: "1.5rem auto",
        backgroundColor: colors.light,
        width: "100%",
        padding: "1rem",
      }}
    >
      <div css={{ position: "sticky", top: 15 }}>
        <Section>
          <H5>{title}</H5>
          <p>{about}</p>
        </Section>

        <RecentPosts />

        <Section>
          <MailingList />
        </Section>

        <Section>
          <H5>نقطة نظام</H5>
          <p>
            حقوق التدوينات محفوظة لأصحابها. والآراء الخاصة بالمدونين لا تُعبّر
            عن وجهة نظر الفهرست.
          </p>
        </Section>
      </div>
    </div>
  );
}

export default Sidebar;
